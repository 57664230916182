<template>
    <div class="ks-right-bar" data-auto-height>
        <div class="ks-wrapper d-flex flex-direction-column">
            <div class="p-3 d-flex flex-direction-row align-items-center" style="border-bottom: 1px solid rgba(57, 81, 155, .2);">
                <a href="javascript:void(0);" class="right-bar-toggle p-0 opacity-5" style="font-size: 2.5em; line-height: 0; color: #000;">&times;</a>
            </div>
            <div class="position-relative flex-1 mt-2 m-2" >
                <div class="d-flex">
                    <input type="text" class="form-control mb-3 mr-5" placeholder="Search.." v-model="search" @keyup.enter="changePage('s')" @keyup="changePage('s')">
                    <button class="btn btn-primary ml-1 btn-sm" @click="changePage('s')">Search</button>
                    <button class="btn btn-secondary ml-3  btn-sm" @click="changePage('r')">Refresh</button>
                </div>
               <div class="scroll">
                    <table class="ks-table table table-hover">
                        <thead>
                            <th>Payee Code</th>
                            <th>Payee Name</th>
                        </thead>
                        <tbody v-if="!load">
                            <tr v-for="(payee,index) in payees.payee " :key="index" @click="selected(index)">
                                <td>
                                    {{payee.payee_code}}
                                </td>
                                <td>
                                    {{payee.payee_name}}
                                </td>
                              
                            </tr>
                        </tbody>
                        <tbody v-else-if="load==false && payees == ''">
                            <tr>
                               <td colspan="2" class="text-center">
                                    No Payee Found
                               </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                               <td colspan="2" class="text-center">
                                    <div class="spinner-border">

                                    </div>
                               </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            <div class="p-3 d-flex flex-direction-row align-items-center" style="border-top: 1px solid rgba(57, 81, 155, .2);">
            <span class="text-bold mr-5">
               <b>{{payees.total}}</b> record(s) 
            </span>
            <!-- <span class="mx-3" v-if="payees.total_page != payees.page">
                <ul class="pagination ">
                    <li class="page-item" >
                        
                       <a class="btn btn-secondary-sm border-0" @click="changePage('p')">  <i class="la la-angle-double-right"></i> load more.. </a>
                    </li>
        
                </ul>
            </span> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show','payees','load'],
    data() {
        return {
            search: '',
            data : {}
        }
    },
    computed : {
     
    },
    methods: {
        open() {
            let body = document.body;
            this.search = ''
            if(body.classList.contains('ks-right-bar-open')) {
                this.data = {};
                body.classList.remove('ks-right-bar-open');
            }
            else body.classList.add('ks-right-bar-open');
        },
        selected(index){
            // var payee = this.payees[index]
            this.open() 
            this.$emitter.emit('selected',index)
        },
        changePage(type){
            switch(type){
                case 'p': 
                    this.$emitter.emit('prev')
                break
                case 'n':
                    this.$emitter.emit('next')
                break
                case 's':
                    this.$emitter.emit('search',this.search)
                break
                case 'r':
                    this.search = ''
                    this.$emitter.emit('refresh',this.search)
                break
            }

        }
    },
    mounted() {
        //  console.log(this.payees)
    },
    watch: {   
        "show": function(n) {
            if(n === false) this.data = {};
            this.open();
        },
    }
}
</script>

<style scoped>
label {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    opacity: 0.8;
}

.dropdown .dropdown-menu {
    border: solid 1px #e6e6e6;
}

.dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f9f9f9;
}
.scroll{
    height: 95%;
}
td {
    cursor:  pointer;
}
</style>