<template>
    <soaContainer>
        <template #header>
            <div class="d-flex">
                <input type="text" class="form-control form-control-sm" placeholder="Search..." style="max-width:50%" v-model="SearchString">
                <button class="btn btn-info btn-sm" @click="search(SearchString)">Search</button>
                <button class="btn btn-secondary btn-sm ml-3" style="align-self: center;"  @click="refresh">Refresh</button>
                <button class="btn btn-primary btn-sm ml-auto" @click="changeComponent('soa-create')">Create</button>
            </div>
        </template>
        <template #body>
            <table class="ks-table table-sm table-hover" >
                <thead>
                    <tr>
                        <th>
                           #
                        </th>
                        <th>
                            SOA No.
                        </th>
                        <th>
                            DATE
                        </th>
                        <th>
                            PAYEE
                        </th>
                    
                        <th>
                            AMOUNT
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody v-if="soa.length > 0" >
                    <tr v-for="(data,index) in soa" :key="index" @click="changeComponent('soa-create',index)"> 
                        <td> {{ index + 1 }} </td>
                        <td> {{ data.soa_no }} </td>
                        <td> {{ data.soa_date }} </td>
                        <td> {{ data.payee }} </td>
                        <td> {{ data.currency }} {{ data.amount }} </td>
                        <td>
                           
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="6">
                            No data found
                        </td>
                    </tr>
                </tbody>
          </table>

        </template>
    </soaContainer>
</template>
<script>
import soaContainer from './soa_container.vue';
export default{
    props:['soa','isEdit','__selected'],
    inject: ['changeComponent','refresh','search'],
    components : {
        soaContainer,
    },
    data(){
        return {
            SearchString : '',
            show : false,
            selected_data : [],
        }
    },
    methods: {
        showSideBar(index){
            this.show = !this.show
            this.selected_data = this.soa[index];
        },
        showCreate(){
            this.selected_data = []
            this.show = !this.show

        }
    }
}


</script>

<style>

</style>