<template>
    <soaContainer>
        <template #header>
            <!-- <h3>
                STATEMENT OF ACCOUNT            
            </h3> -->
            <!-- <button class="btn btn-success btn-sm" >Create</button> -->
            
<!--      
            <button class="btn btn-success btn-sm" @click="showPayee">Select Payee</button> -->
        </template>
        <template #body>
            <div class="d-flex justify-content-center">
                <div class="card mr-2" style="width: 50%;">
                    <div class="card-header d-flex">     
                        <strong> STATEMENT OF ACCOUNT     </strong>
                            <div class="d-flex ml-auto">
                                
                               <span v-if="is_Edit">
                                    <button class="btn btn-info btn-sm mr-3" @click="editMode"  v-if="!isEditmode"> Edit</button>
                                    <button class="btn btn-success btn-sm" @click="create" v-if="isEditmode">Update</button>
                                    <button class="btn btn-primary btn-sm" @click="print"  v-if="!isEditmode">Print</button>

                               </span>
                               <span v-else>
                                    <button class="btn btn-success btn-sm" @click="create"> Create</button>
                               </span>
                                    <button class="btn btn-secondary btn-sm ml-3" @click="changeComponent('soa-rm')"  v-if="!isEditmode">Close</button>
                                    <button class="btn btn-secondary btn-sm ml-3" @click="cancel" v-if="isEditmode">Cancel</button>

                            </div>
                    </div>
                    <div class="card-body d-flex justify-content-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between">
                                <div class="mr-3">
                                    <strong>SOA No.</strong>
                                    <input type="text" class="form-control form-control-sm" v-model="form.soa_no" :readonly="isReadonly" v-if="!is_Edit">
                                    <strong v-else class="form-control form-control-sm" > {{ form.soa_no }}</strong>
                                </div>
                                <div class="">
                                    <strong>SOA Date</strong>
                                    <input type="date" class="form-control form-control-sm" v-model="form.soa_date" :readonly="isReadonly" >
                                </div>
                            </div>
                            <div class="d-flex flex-column mt-4">
                                <div>
                                    <h5>
                                        <strong>Charge to</strong>
                                    </h5>
                                </div>
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <strong>Code</strong>
                                        <input type="text" class="form-control form-control-sm"  v-model="form.payee_code" readonly>
                                    </div>
                                    <div class="">
                                        <strong>Name</strong>
                                        <input type="text" class="form-control form-control-sm" v-model="form.payee" readonly>
                                    </div>
                                    <div class="d-flex align-items-end ml-3">
                                        <button class="btn btn-secondary btn-sm" @click="showPayee" :disabled="isReadonly" >Select</button>
                                    </div>
                             </div>
        
                            </div>
                            <div class="d-flex justify-content-between mt-4">
                            
                                    <div class="d-flex flex-column">
                                        <strong>Currency</strong>
                                        <select name="" id="" class="form-select " style="width:150px" v-model="form.currency" :disabled="isReadonly" > 
                                            <option value="PHP" >PHP</option>

                                            <option value="US$">USD</option>
                                        </select>
                                    </div>
                                    <div class="ml-3">
                                        <strong>Amount</strong>
                                        <input type="number" @change="setTwoNumberDecimal" min="0" max="10000000000" step="0.25" placeholder="0.00" class="form-control" v-model="form.amount" :readonly="isReadonly" />
                                    </div>
                            </div>
                             <div class="d-flex justify-content-between mt-4">
                            
                                    <div class="d-flex flex-column">
                                        <strong>Prepared by :</strong>
                                        <input type="text" class="form-control form-control-sm" v-model="form.prepared" readonly>
                                        
                                    </div>
                                    <div class="ml-3">
                                        <strong>Checked by :</strong>
                                        <input type="text" class="form-control form-control-sm" v-model="form.checked" :readonly="isReadonly">
                                    </div>
                            </div>
                            <div class="d-flex flex-column mt-4">
                                <strong>Particulars</strong>
                                <textarea name="Text1" cols="10" rows="5" class="form-control" placeholder="Input Particulars" 
                                    v-model="form.particulars" :readonly="isReadonly" ></textarea>
                                <p class="text-end" style="font-size: 12px;"> {{ checklength }} / 200</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
 

    </soaContainer>

    <payee-modal :show="show"  :payees="payees" :load="payeeLoad"  />
</template>
<script>
import soaContainer from './soa_container.vue';
import PayeeModal from '../../crf/crf_payee_modal.vue';
import  jsPDF  from "jspdf";


export default {
    props:['soa','isEdit','__selected'],
    components : {
        PayeeModal,
        soaContainer
    },
    inject :['changeComponent'],
    data(){
        return {
            // isEdit : false,
            isEditmode : false,
            is_Edit : false,
            show : false,
            payees : [],
            payeeLoad: false,
            data: {
                page: 1,
                search: ''
            },
            form : {
                soa_date : this.$moment().format('YYYY-MM-DD'),
                currency : 'PHP',
                isCreate : false,
            },
            default : {},
        }
    },
    computed : {
        isReadonly(){
            if(this.is_Edit){
                return !this.isEditmode
            }
            else{
                return false
            }
          
        },
        checklength(){
            if(this.form.particulars) {

                return this.form.particulars.length;


            }
            else return 0
           
        }
    },
    methods: {
    async fetchpayee(){
         this.payeeLoad = true
         this.payees =  await this.API.fetchpayee(this.data)
         this.data.search = ''
        //  console.log(this.payees);

         // console.log(this.payees)
         this.payeeLoad = false
    },
    setTwoNumberDecimal(event){
        event.value = parseFloat(event.value).toFixed(2);
    },
    showPayee(){
        this.fetchpayee()
        this.show = !this.show
    },
    editMode(){
        this.isEditmode =  !this.isEditmode;
    },
    async create(){
        // console.log(this.form)
            await this.API.createSoa(this.form)
            .then( res => {
                this.$jlalert.show('Success', res.status != 200);

                // console.log(res.data);
                this.is_Edit = true;
                this.isEditmode = false;

                this.default = { ...res.data[0] }
                this.form = { ...res.data[0] }
                this.form.soa_date = this.$moment(this.form.soa_date).format('YYYY-MM-DD')
                this.form.amount = this.HELPER.reformat(this.form.amount);
                this.default.amount = this.HELPER.reformat(this.default.amount);
                this.default.soa_date = this.$moment(this.default.soa_date).format('YYYY-MM-DD')

                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    },
    cancel(){
        this.form = {...this.default};
        // console.log(this.__selected)
        this.editMode();
    },
    async downloadPDF(){
        await this.API.downloadSOA(this.default)
            .then(res => {
                    var file = res.data;
                    
                    var mime_type = file.mime_type;
                    var filename = file.name;

                    //FILE
                    if(mime_type != 'link') {
                        var binary = 'data:'+mime_type+';base64,'+file.binary;
                        const link = document.createElement('a');
                        link.href = binary;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
   
                // console.log(res)


            })
            .catch(err => {
                // console.log(err)
                this.$jlalert.show(err)
 
            })

    },
    print(){
        const doc = new jsPDF(
                {
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts:true
                }
        )

        var img = new Image()
        let date = new Date()
        let dateToday = this.$moment(this.default.soa_date).format('LL')
        // let dateToday = this.$moment();
        let year = date.getFullYear()
        // let title = "STATEMENT OF ACCOUNT";
        let soa_num = this.default.soa_no;
        var particulars = this.default.particulars;
        var textLines = doc.splitTextToSize(particulars, 200);
        var checkby = this.default.checked;
        var preparedby = this.default.prepared;
            //set src
            img.src = require('@/assets/logo.png')
            //convert img to base64
            this.encodeImage(img)

            //addPage set to desire size (a0 - a10,b0 - b10, c0 - c10,dl,letter,government-letter,government-letter,junior-legal,ledger,tabloid,credit-card, or numeric w h)and landscape or portrait 'l' or 'p'
            doc.addPage()
            //delete the first page (meron kasing laman kaagad kaya denelete ko hehe)
            doc.deletePage(1)
       
            doc.setFont(undefined,'bold')
            doc.setFontSize(25)
            // doc.text(title,90,20) 

            doc.setFontSize(16)


            // doc.addImage(img, 'png', 15, 20, 60, 30)
            
            // doc.text('No.', 210,35);
            // doc.text('Date:', 210,45);

            // doc.text('Charge to :', 15,60);
            // doc.text('Particular :', 15,90);
            // doc.text('Amount :', 13,130);


            // doc.text('Prepared by :', 15,170);
            // doc.text('Checked by :', 80,170);
            // doc.text('Approved by :', 150,170);
            // doc.text('Received by :', 220,170);

            // doc.line(x, y, x, y);
            doc.setLineWidth(.7);

            // doc.line(15, 195, 50, 195);
            // doc.line(80, 195, 115, 195);
            // doc.line(150, 195, 185, 195);
            // doc.line(220, 195, 255, 195);



            doc.setFont(undefined,'normal') 
            doc.text(this.default.payee,40,67);
              let amount =  new Intl.NumberFormat().format(this.default.amount);
              doc.text(amount,40,120 );
            // doc.text(this.default.particulars,50,90);
            doc.setFontSize(12)
            for (var i = 0; i < textLines.length; i++) {
                    doc.text(textLines[i], 40, 77.9 + (i * 10));
                }

            
            

            
            doc.text(checkby,67,170 );
            doc.text(preparedby,20,170 );

            doc.text("SOA#" + soa_num,160,50.5);
            doc.text(dateToday,160,54.3);

            


            doc.setTextColor('red')
            doc.setFontSize(18)
            // doc.text(soa_num, 230,35);

            doc.setProperties({
                title: "Print " + year + '-' + this.default.soa_no
            });
            
            doc.output('dataurlnewwindow')

    },
    encodeImage(img){
        
        const getBase64StringFromDataURL = (dataURL) =>
        dataURL.replace('data:', '').replace(/^.+,/, '');

        fetch(img.src)
            .then((res) => res.blob())
            .then((blob) => {
                // Read the Blob as DataURL using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    // console.log(reader.result);
                    // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...
                    this.image = reader.result;

                    // Convert to Base64 string
                    const base64 = getBase64StringFromDataURL(reader.result);
                    console.log(base64);
                    // Logs wL2dvYWwgbW9yZ...
                };
                reader.readAsDataURL(blob);
            });
    },

},
    mounted(){
        let instance = this;
         this.$emitter.on('search', function(search){
            instance.data.search = search
   
            instance.fetchpayee()
        })
        this.$emitter.on('selected', function(index){
            // console.log(instance.payees.payee)
            // console.log(index)

            instance.form.payee_code = instance.payees.payee[index].payee_code
            instance.form.payee = instance.payees.payee[index].payee_name
            // console.log(instance.form)
        })
         this.$emitter.on('refresh', function(){
            // instance.data.search = search
            instance.data.search = ''
            instance.fetchpayee()
        })

        this.fetchpayee()
      console.log(this.form.isCreate)
    },
    beforeUnmount(){
        this.form = {}
    },
    watch : {
        "isEdit":{
            handler: function(newValue){
                // console.log('Title changed from ' + oldTitle + ' to ' + newTitle)
                if(newValue == true){
                    this.form ={ ...this.__selected}
                    this.default = {...this.__selected }
                    // this.form.soa_date = this.$moment(this.form.soa_date).format('YYYY-MM-DD')

                    this.form.soa_date = this.$moment(this.form.soa_date).format('YYYY-MM-DD')
                    // let length = this.form.amount.length;
                    // let amount = this.form.amount.replace(',','');
                    this.form.amount = this.HELPER.reformat(this.form.amount);
                    this.default.amount = this.HELPER.reformat(this.default.amount);

                    this.checked = this.form.checked
                 
                    this.is_Edit = newValue;
                    this.default.soa_date = this.$moment(this.default.soa_date).format('YYYY-MM-DD')
                    this.form.isCreate = false;

                }
                else{
                    this.form.isCreate = true;
                }

                // let date = this.$moment("YYYY-MM-DD",this.form.soa_date)

                // console.log(date)
               
            },
            immediate: true,
        }
    }
}

</script>