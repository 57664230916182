<template>

        <component :is="activeComponents" :soa="soa_data" :isEdit="isEdit" :__selected="selected_data"></component>


</template>
<script>
import SoaCreate from './components/soa_create.vue'
import SoaRm from './components/soa_rm.vue'
export default {
    components : {
        SoaCreate,
        SoaRm
    },
    data(){
        return {
            activeComponents : 'soa-rm',
            soa_data : '',
            searcher : '',
            isEdit : false,
            selected_data : {},
        }
    },
    provide(){
        return {
            "changeComponent" : this.changeComponent,
            "search" : this.search,
            "refresh" : this.refresh,
        }
    },
    methods : {
        async soaGet(){
            await this.API.getSoa(this.searcher)
            .then(res => {
                this.soa_data = res.data
                // console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
        },
        async search(data){
            this.searcher = data
            await this.soaGet()
        },
        async changeComponent(data,index = null){
 
            this.activeComponents = data;
            
            if(index != null){
                this.selected_data = this.soa_data[index];
                // console.log(this.selected_data)
                this.isEdit = true;
            }
            

            // console.log(data);
        },
        async refresh(){
            this.searcher = ''
            await this.soaGet()
        }
    },
    beforeMount(){
        this.soaGet()
        
    },
    mounted(){
        // console.log('Moment : ' + this.HELPER.subtractedDate(30))
        // console.log('getDate : ' + this.API.getDate())
    },
    watch : {
        "activeComponents":{
            handler: function(newValue){
                // console.log('Title changed from ' + oldValue + ' to ' + newValue)

                if(newValue == 'soa-rm'){
                    // this.soaGet()
                    this.selected_data = {}
                    this.isEdit = false
                    this.refresh()
                }
         
                // let date = this.$moment("YYYY-MM-DD",this.form.soa_date)

                // console.log(date)
               
            },
            immediate: true,
        }
    }
}

</script>

<style>

</style>